<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      :attach="$el"
      :top="isOutOfView"
      transition="scale-transition"
      origin="top right"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="internalText"
          :readonly="readonly"
          :disabled="disabled"
          :label="label"
          :required="required"
          :rules="rules"
          :clearable="clearable"
          append-icon="mdi-arrow-expand"
          @click:append="on.click"
          @keydown.enter="on.click"
        />
      </template>
      <resize-observer @resize="recomputeHeight">
        <v-card class="background-plain ma-0 text-popup">
          <v-card-text>
            <v-textarea
              ref="textarea"
              v-model="internalText"
              :readonly="readonly"
              :disabled="disabled"
              :rules="rules"
              :clearable="clearable"
              :counter="counter"
              full-width
              auto-grow
              rows="4"
              dense
              autofocus
              @keydown.enter.prevent
              @focus="resize"
              @input="$triggerResize"
            />
          </v-card-text>
        </v-card>
      </resize-observer>
    </v-menu>
  </div>
</template>

<script>
export default {
  components: {
    ResizeObserver: () => import('@/components/resize-observer.vue')
  },
  computed: {
    internalText: {
      get () {
        return this.text
      },
      set (value) {
        this.$emit('update:text', value)
      }
    }
  },
  data () {
    return {
      isOutOfView: false
    }
  },
  methods: {
    recomputeHeight (rect) {
      if (!this.$el) { return }
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$el.getBoundingClientRect().top + rect.bottom)
    },
    resize () {
      this.$refs.textarea.calculateInputHeight()
      setTimeout(this.$triggerResize, 150)
    }
  },
  props: {
    clearable: Boolean,
    counter: Number,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    required: Boolean,
    rules: Array,
    text: String
  }
}
</script>

<style lang="stylus" scoped>
>>>.v-textarea .v-input__append-inner
  align-self flex-end
  padding-bottom 8px

.text-popup
  min-width 500px
</style>
